/* refer the styles from package folder */
.customFullFileBrowser {
  width: 100%;
  /* Ensure it takes up full parent width */
  border: 1px solid #fff;
  background-color: #f9f9f9;
  padding: 10px;
  /* Add more custom styles as needed */
  height: 70vh;
}

.hiddenComponent {
  display: none;
}

/* 
.MuiList-root {
  margin: 20px;
  padding: 0;
  position: relative;
  list-style: none;
} */

/* Your custom styles for the chonky component */
.chonky-component {
  background-color: #f0f0f0;
  padding: 10px;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #333;
}

.chonky-component h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.chonky-component p {
  font-size: 1em;
  line-height: 1.6;
}

/* stylelint-disable docusaurus/copyright-header */
/**
 * Any CSS included here will be global. The classic template
 * bundles Infima by default. Infima is a CSS framework designed to
 * work well for content-centric websites.
 */

/* You can override the default Infima variables here. */
:root {
  --ifm-color-primary: #25c2a0;
  --ifm-color-primary-dark: rgb(33, 175, 144);
  --ifm-color-primary-darker: rgb(31, 165, 136);
  --ifm-color-primary-darkest: rgb(26, 136, 112);
  --ifm-color-primary-light: rgb(70, 203, 174);
  --ifm-color-primary-lighter: rgb(102, 212, 189);
  --ifm-color-primary-lightest: rgb(146, 224, 208);
  --ifm-code-font-size: 95%;
}

.docusaurus-highlight-code-line {
  background-color: #fff;
  display: block;
  margin: 0 calc(-1 * var(--ifm-pre-padding));
  padding: 0 var(--ifm-pre-padding);
}

div[class^="playgroundHeader__"] {
  text-transform: capitalize;
  padding: 5px 10px !important;
  margin: 0 !important;
  letter-spacing: 0;
  line-height: auto;
}

div[class^="playgroundContainer__"] div[class^="playgroundHeader__"]:first-of-type {
  font-size: 0;
}

div[class^="playgroundContainer__"] div[class^="playgroundHeader__"]:first-of-type::before {
  content: 'Live Example';
  font-size: 1rem;
}

/* Reduce the font size... */
div[class^="playgroundEditor"],
.prism-code textarea,
.prism-code pre {
  font-size: 0.9em !important;
}


.chonky-chonkyRoot {
  color: rgba(0, 0, 0, 0.87);
  border: solid 0px rgba(0, 0, 0, 0.12);
  height: 100%;
  display: flex;
  padding: 8px;
  font-size: 15px;
  box-sizing: border-box;
  text-align: left;
  font-family: sans-serif;
  user-select: none;
  touch-action: manipulation;
  border-radius: 4px;
  flex-direction: column;
  -ms-user-select: none;
  moz-user-select: none;
  background-color: #fff;
  webkit-user-select: none;
  webkit-touch-callout: none;
  position: absolute;
}