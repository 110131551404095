@keyframes blink {
  100% {
      transform: scale(2, 2);
      opacity: 0;
  }
}
.online-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #089900;
  border-radius: 50%;
  position: absolute;
  top: 22px;
  right: 0;
}
.offline-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #f44336;
  border-radius: 50%;
  position: absolute;
  top: 22px;
  right: 0;
}
.online-indicator .blink {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #089900;
  opacity: 0.7;
  border-radius: 50%;
  animation: blink 1s linear infinite;
}
.offline-indicator .blink {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #f44336;
  opacity: 0.7;
  border-radius: 50%;
  animation: blink 1s linear infinite;
}