/* refer the styles from package folder */
.custom-full-file-browser {
    width: 100%;
    /* Ensure it takes up full parent width */
    border: 1px solid #fff;
    background-color: #f9f9f9;
    padding: 10px;
    /* Add more custom styles as needed */
}

.hidden-component {
    display: none;
}

/* 
.MuiList-root {
    margin: 20px;
    padding: 0;
    position: relative;
    list-style: none;
} */